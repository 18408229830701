import { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import logo from '../assets/images/logo.webp';

import '../styles/header.scss';

const Header = () => {
  const [, setScrollY] = useState(0);
  const [header, setHeader] = useState('header default-pos clearfix');

  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
      setScrollY(window.scrollY);

      if (window.scrollY> 100) {
        setHeader('header scrolled-pos clearfix');
      } else {
        setHeader('header default-pos clearfix');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <header className={header}>
      <div className="logo-container">
        <a href="/" className="logo">
          <img width="50" height="50" src={logo} alt="Kavalier logo" />
          <span className='logo-text'>Kavalier</span>
        </a>
        <span>+38 068 625 22 12 <span id="logo-contacts-line">|</span> kavalier.vino@gmail.com</span>
      </div>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn">
        <FontAwesomeIcon className="navicon" icon={solid('bars')} size="1x" />
      </label>
      <ul className="menu">
        <li>
          <HashLink className="menu_item" to="/" onClick={ () => document.getElementById('menu-btn').checked = false}>
            <FontAwesomeIcon className="icon_menu_li" icon={solid('house')} size="1x" fixedWidth />
              Головна
          </HashLink>
        </li>
        <li>
          <HashLink className="menu_item" to="/products" onClick={ () => document.getElementById('menu-btn').checked = false}>
            <FontAwesomeIcon className="icon_menu_li" icon={solid('wine-glass-empty')} size="1x" fixedWidth />
              Вина
          </HashLink>
        </li>
        <li>
          <HashLink className="menu_item" smooth to="#about-wrapper" onClick={ () => document.getElementById('menu-btn').checked = false}>
            <FontAwesomeIcon className="icon_menu_li" icon={solid('user-ninja')} size="1x" fixedWidth />
            Про нас
          </HashLink>
        </li>
      </ul>
    </header>
  );
}

export default Header;