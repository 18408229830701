import { useRef } from 'react';
import styled from 'styled-components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { HashLink } from 'react-router-hash-link';

import ButtonArrow from '../components/buttons/ButtonArrow';
import Footer from '../components/Footer';

import 'animate.css/animate.min.css';
import {
  mainWallpaper,
  vinoBiancoWallpaper,
  varietaleWallpaper,
  siciliaDocWallpaper,
  fragolinoWallpaper,
  merlotRosatoWallpaper,
  profilePicture
} from '../js/images';

const MainSectionStyle = styled.section`
  --section-bg-s1-margin: 0 auto;
  --section-bg-s1-min_height: 90vh;
  --section-bg-s1-max_height: 100%;
  --section-bg-s1-padding: 20px;
  --section-bg-s1-bg_color: rgba(0,0,0, 0.6);
  --section-bg-s1-bg_image: url(${mainWallpaper});
  --section-bg-s1-bg_position: center;
  --section-bg-s1-bg_repeat: no-repeat;
  --section-bg-s1-bg_size: cover;
  --section-bg-s1-box_shadow: none;
  overflow: hidden;
  height: 100%;
  width: 100%;

  header {
    margin: 0 0 20px 0;
  }

  .grid-col-center {
    align-self: end;
    vertical-align: middle;
  }
  .top-25 {
    position: unset;
  }
  .bottom-50 {
    position: unset;
    grid-column: 6/8;
    align-self: end;
    vertical-align: middle;
  }

  @media screen and (min-width: 1281px) {
    padding-top: 60px;
  }

  @media screen and (min-width: 1441px) {
    padding-bottom: 0;
  }
`;

const LogoImage = styled.img`
  width: 260px;
  max-width: 100%;
  height: auto;
  display: block;
  position: relative;
`;

const GeneralSectionStyle = styled.section`
  --section-bg-s1-margin: 0 auto;
  --section-bg-s1-min_height: 100vh;
  --section-bg-s1-max_height: 100%;
  --section-bg-s1-padding: 20px;
  --section-bg-s1-bg_color: rgba(0,0,0, 0.83);
  --section-bg-s1-bg_image: url(${p => p.image});
  --section-bg-s1-bg_position: ${p => p.position};
  --section-bg-s1-bg_repeat: no-repeat;
  --section-bg-s1-bg_size: cover;
  --section-bg-s1-box_shadow: none;
  height: 100%;
  scroll-margin-top: 90px;

  h1 {
    --section-text-margin: 0 auto;
    --section-text-display: block;
    --section-font_family: 'PFMonumentaPro', sans-serif;
    --section-font_size: 3vmax;
    --section-font_weight: bold;
    --section-font_style: normal;
    --section-text_align: ${p => p.align};
    --section-text-line_height: 1.2;
    color: ${p => p.color || 'black'}
  }

  h2 {
    --section-text-margin: 0 auto;
    --section-text-padding: 0 0 20px 0;
    --section-text-display: block;
    --section-font_family: 'Satoshi', sans-serif;
    --section-font_weight: normal;
    --section-font_style: normal;
    --section-text_decoration: none;
    --section-text_align: center;
    --section-text-line_height: 1.3;
    color: ${p => p.color || 'black'}
  }

  .line {
    margin: 10px;
    border-bottom: 1px solid #cc0036;
  }
  
  .bottom-60 {
    position: unset;
    grid-column: 6/8;
    align-self: end;
    vertical-align: middle;
  }

  h1.align-right {
    text-align: right;
  }

  .section-text {
    grid-column: 8/13;
    width: 100%;
    padding-top: 50px;
  }
  
  .section-text-left {
    grid-column: 1/6;
    width: 100%;
    padding-top: 50px;
  }
  
  @media screen and (max-width:640px) {
    h1, .align-right {
      --section-text_align: center;
      text-align: center !important;
    }

    .section-text, .section-text-left {
      background-color: ${p => p.bgColor || 'rgba(220,220,220, 0.7)'};
      padding: 5px;
      grid-column: 1/13;
      width: 100%;
      padding-top: 50px;
    }
  }
  
  @media screen and (min-width:640px) and (max-width:860px) {
    h2 {
      --section-text-line_height: 1;
    }
  }
  
  @media screen and (min-width: 641px) {
    h1 {
      --section-text_align: left;
      padding-left: 10px;
    }
    h2 {
      text-align: left;
    }
    .line {
      border-bottom: 2px solid #cc0036;
    }
    .section-content-text-left {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      opacity: 1;
      text-align: left;
      width: 80%;
      float: left;
      padding-left: 10px;
    }
	.section-content-text {
      animation-duration: 1s;
      opacity: 1;
      text-align: right;
      width: 80%;
      float: right;
    }
    .section-text {
      float: right;
      position: relative;	 
      padding-top: 50px;
    }
    .section-content-text h2 {
      text-align: left;
    }
    .line {
      border-bottom: 2px solid #cc0036;
    }
  }
  
  @media screen and (min-width: 1025px) {
	.section-text {
      grid-column: 7/12;
    }
    .section-text-left {
      grid-column: 2/7;
    }
  }

  @media screen and (min-width: 1441px) {
    padding-bottom: 0;
  }
}`;

const MainPage = () => {
  let vinoBiancoRef = useRef(0);
  let fragolinoRef = useRef(0);
  let merlotRosatoRef = useRef(0);
  let varietaleRef = useRef(0);
  let siciliaDocRef = useRef(0);
  let aboutRef = useRef(0);

  return (
    <div className="body-container">
      <MainSectionStyle className="grid section">
        <div className="grid-wrapper-column grid-col-center top-25">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <LogoImage width="512" height="512" fetchpriority="high" src={profilePicture} alt="Logo Image" />
          </AnimationOnScroll>
        </div>
        <div className="bottom-50">
          <ButtonArrow className="center" onClick={vinoBiancoRef} />
        </div>
      </MainSectionStyle>
      <GeneralSectionStyle image={vinoBiancoWallpaper} position="left" align="right" id="web-development" className="grid section" ref={vinoBiancoRef}>
        <header className="grid-wrapper-column section-text">
          <div>
            <h1 className="align-right">VINO BIANCO</h1>
            <h1 className="align-right">SEMI DOLCE</h1>
            <div className="grid-col-all line" />
          </div>
          <AnimationOnScroll animateIn="animate__fadeIn" className="section-content-text">
            <h2>
              <div>Бiле напівсолодке вино. Міцність: 12%. Об'єм: 750 мл.</div>
              <div>Виготовлено в Італії.</div>
            </h2>
            <h2>
              В складі 100% натуральної сировини - виноград різних сортів з різних регіонів Італії.
              Вино з насиченим свіжим ароматом, де відчуваються нотки фруктiв.
            </h2>
            <h2>
              VINO BIANCO SEMI DOLCE має тонкий солодкуватий смак. Приємно освіжає завдяки стійкому фруктовому післясмаку.
              Вино сонячного світло-жовтого кольору. Стане вишу- каним доповненням овочевих страв, білого м'яса, перших страв та молодих сирів.
              Разом з десертами смакує просто неймовірно! Рекомендується подавати охолодже- ним до + 8°C до + 10°C.
            </h2>
            <h2>
              Це натуральне вино, тому виготовлено без додавання спирту та концентратів. Містить сульфіти, тому не рекомендовано вживати особам,
              що мають алергію на діоксид сульфуру.
            </h2>
          </AnimationOnScroll>
        </header>
        <div className="bottom-60">
          <ButtonArrow className="center button-dark" onClick={siciliaDocRef} />
        </div>
      </GeneralSectionStyle>
      <GeneralSectionStyle image={siciliaDocWallpaper} position="center" align="left" color="white" bgColor="rgba(0,0,0,0.5)" className="grid section" ref={siciliaDocRef}>
        <header className="grid-wrapper-column section-text-left">
          <div>
            <h1>SICILIA DOC</h1>
            <h1>NERO D'AVOLA ROSSO</h1>
            <div className="grid-col-all line" />
          </div>
          <AnimationOnScroll animateIn="animate__fadeIn" className="section-content-text-left">
            <h2>
              <div>Cухе червоне вино. Міцність: 12%. Об'єм: 750 мл</div>
              <div>Виготовлено в Італії.</div>
            </h2>
            <h2>
              Вино має найвищу категорію якості (DOC denominazione 
              di origine controllata), тобто захищене оригінальне 
              походження на конкретній територiї та назву.
            </h2>
            <h2>
              Виготовляється на Сицилії зі 100% натуральноï сировини - 
              червоного винограду сорту Неро д‘Авола. Вино з ніжним 
              ароматом та відтінками червоних фруктів та квітів, також 
              можуть відчуватися ягоди: малина, вишня та ожина. 
              У SICILIA DOС NERO D'AVOLA ROSSO гармонійний та 
              добре структурований смак, серднiй рiвень терпкостi, 
              невелика кислотність та таніни. Післясмак довгий та приємний. Вино гарного червоно-гранатового кольору. 
              Чудово пасуватиме до м'ясних страв, пасти з песто, 
              паштетів, а також сирів середньої витримки. Найкраще 
              подавати за температури від + 16°С до + 20°С.
            </h2>
            <h2>
              Це натуральне сухе вино, тому в нього не додавали спирт 
              та концентрати. Містить сульфіти, тому не рекомендовано 
              вживати особам, що мають алергію на діоксид сульфуру.
            </h2>
          </AnimationOnScroll>
        </header>
        <div className="bottom-60">
          <ButtonArrow className="center" onClick={merlotRosatoRef} />
        </div>
      </GeneralSectionStyle>
      <GeneralSectionStyle image={merlotRosatoWallpaper} position="right" align="right" className="grid section merlot" ref={merlotRosatoRef}>
        <header className="grid-wrapper-column section-text">
          <div>
            <h1 className="align-right">MERLOT ROSATO</h1>
            <h1 className="align-right">VINO VARIETALE</h1>
            <div className="grid-col-all line" />
          </div>
          <AnimationOnScroll animateIn="animate__fadeIn" className="section-content-text">
            <h2>
              <div>Сухе рожеве вино. Міцність: 12%. Об'єм: 750 мл.</div>
              <div>Виготовлено в Італії.</div>
            </h2>
            <h2>
              Виробляється в регіоні Венето зі 100% натуральної сировини - винограду сорту Мерло. Вино з насиченим свіжим 
              ароматом та нотками фруктів.
            </h2>
            <h2>
              MERLOT ROSATO VINO VARIETALE – має м'який та добре 
              збалансований смак, присмак приємний та фруктовий. 
              Післясмак довгий та оксамитовий. Вино солом'яного 
              кольору із зеленуватим відблиском.
            </h2>
            <h2>
              Стане чудовим аперитивом до холодних страв, риби, 
              білого м'яса, овочевих салатів та м'яких сирів. Найкраще 
              подавати охолодженим від + 10°С до + 16°С.
            </h2>
            <h2>
              Це натуральне сухе вино, тому виготовлено без додавання спирту та концентратів. Містить сульфіти, тому не 
              рекомендовано вживати особам, що мають алергію на 
              діоксид сульфуру.
            </h2>
          </AnimationOnScroll>
        </header>
        <div className="bottom-60">
          <ButtonArrow className="center button-dark" onClick={fragolinoRef} />
        </div>
      </GeneralSectionStyle>
      <GeneralSectionStyle image={fragolinoWallpaper} position="center" align="left" color="white"
        bgColor="rgba(0,0,0,0.5)" className="grid section fragolino" ref={fragolinoRef}>
        <header className="grid-wrapper-column section-text-left">
          <div>
            <h1>FRAGOLINO</h1>
            <div className="grid-col-all line" />
          </div>
          <AnimationOnScroll animateIn="animate__fadeIn" className="section-content-text-left">
            <h2>
              Фраголіно Россо
              (Fragolino Rosso Kavalier)
            </h2>
            <h2>
              Фраголіно Б‘янко
              (Fragolino Bianco Kavalier)
            </h2>
            <h2>
              ТМ “КАВАЛЕР”
            </h2>
            <h2>
              Країна походження : Італія.
            </h2>
            <h2>
              Смак: приємний, солодкий, фруктовий. Запах: фруктовий, свіжий і ароматний. Температура сервірування: 
              від +6 °C до +8 °C.
            </h2>
            <h2>
              Містить сульфіти, тому не рекомендовано вживати особам, що мають 
              алергію на діоксид сульфуру.
            </h2>
          </AnimationOnScroll>
        </header>
        <div className="bottom-60">
          <ButtonArrow className="center" onClick={varietaleRef} />
        </div>
      </GeneralSectionStyle>
      <GeneralSectionStyle image={varietaleWallpaper} position="left" align="right" className="grid section" ref={varietaleRef}>
        <header className="grid-wrapper-column section-text">
          <div>
            <h1 className="align-right">VARIETALE</h1>
            <h1 className="align-right">CHARDONNAY BIANCO</h1>
            <div className="grid-col-all line" />
          </div>
          <AnimationOnScroll animateIn="animate__fadeIn" className="section-content-text">
            <h2>
              <div>Cухе біле сортове вино. Міцність: 12%. Об'єм: 750 мл.</div>
              <div>Виготовлено в Італії.</div>
            </h2>
            <h2>
              В складі зі 100% натуральної сировини - винограду сорту 
              Шардоне з різних регіонів Італії. Вино з нiжним та вишуканим ароматом та нотками тропічних фруктів.
            </h2>
            <h2>
              VARIETALE CHARDONNAY BIANCO має приємний, в міру 
              збалансований солодкуватий смак. Текстура оксамитова, середньоï терпкостi, з багатими тонами пряних фруктів. Післясмак довгий та з легкою кислинкою. 
              Вино святкового золотисто-солом'яного кольору. Ідеально пасує до 
              страв традиційної італійської кухні, рибних страв, морепродуктів, молодих сирів та фруктових десертів. Найкраще подавати охолодженим до + 10ºС.
            </h2>
            <h2>
              Це натуральне сухе вино, тому виготовлено без додавання спирту та концентратів. Містить сульфіти, тому не 
              рекомендовано вживати особам, що мають алергію на 
              діоксид сульфуру.
            </h2>
          </AnimationOnScroll>
        </header>
        <div className="bottom-60">
          <HashLink className="menu_item" smooth to="#about-wrapper">
            <ButtonArrow className="center button-dark" to="#about-wrapper" />
          </HashLink>
        </div>
      </GeneralSectionStyle>
      <Footer ref={aboutRef}></Footer>
    </div>
  );
};

export default MainPage;