import styled from 'styled-components';

import { aboutWallpaper, profilePicture } from '../js/images';

import '../styles/header.scss';

const LogoImage = styled.img`
  width: 260px;
  max-width: 100%;
  height: auto;
  display: block;
  position: relative;
`;

const AboutSectionStyle = styled.article`
--section-bg-s1-margin: 0 auto;
  --section-bg-s1-min_height: 90vh;
  --section-bg-s1-max_height: 100%;
  --section-bg-s1-padding: 20px;
  --section-bg-s1-bg_color: rgba(0,0,0, 0.6);
  --section-bg-s1-bg_image: url(${aboutWallpaper});
  --section-bg-s1-bg_position: center;
  --section-bg-s1-bg_repeat: no-repeat;
  --section-bg-s1-bg_size: cover;
  --section-bg-s1-box_shadow: none;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .bottom-50 {
    position: unset;
    grid-column: 1/13;
    align-self: center;
    vertical-align: middle;
    color: #fff;
    text-align: center;
    font-family: 'Satoshi', sans-serif;
    padding-bottom: 100px;
    line-height: 1.5;
    font-size: 20px;
  }

  .about-section {
    grid-column: 1/13;
    width: 100%;
    padding-top: 50px;
    align-self: end !important;
    vertical-align: middle !important;
  }

  #about-line {
    padding: 0 10px;
    color: #cfa476;
  }

  @media screen and (min-width: 1281px) {
    padding-top: 60px;
  }

  @media screen and (min-width: 1441px) {
    .grid-col-center {
      align-self: center;
      vertical-align: middle;
    }
  }
`;

const Footer = () => {

  return (
    <AboutSectionStyle id="about-wrapper" className="grid section">
      <div className="grid-wrapper-column grid-col-center about-section">
        <LogoImage width="512" height="512" fetchpriority="high" src={profilePicture} alt="About Section Image" />
      </div>
      <div className="bottom-50">
        +38 068 625 22 12 <span id="about-line">|</span> kavalier.vino@gmail.com
      </div>
    </AboutSectionStyle>
  );
}

export default Footer;