const biancoSemiData = {
  name: 'Kavalier (Кавальєр) Vino Bianco Senza Semi Sweet',
  taste: 'приємний, добре збалансований',
  aroma: 'насичений, свіжий, з нотками фруктів',
  gastronomicCombinations: 'відмінно підходить для супроводу десертів, добре поєднується з основними та овочевими стравами',
  grapeVariety: 'купаж білих сортів',
  manufacturer: 'Kavalier, Італія.',
  region: 'Сицилія',
  classification: 'IGP, DOP',
  winePotential: 'Пити зараз',
  sweetness: 'Напівсолодке',
  coverType: 'Корок',
  packagingType: 'Скло',
  grapesVariety: 'Купаж',
  productType: 'Вино',
  alcoholStrength: '12%',
  capacity: '0,75 л',
  wineColor: 'Біле',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const cabernetData = {
  name: 'Kavalier (Кавальєр) Varietale Cabernet Sauvignon Rosso',
  taste: 'з трохи помітною нотою смородини',
  aroma: 'інтенсивний, насичений, наповнений нотками фруктів',
  gastronomicCombinations: 'підходить для супроводу м\'ясних страв, печива, дичини, витриманих сирів',
  grapeVariety: '100% - Каберне Совіньон',
  manufacturer: 'Kavalier, Італія.',
  region: 'Сицилія',
  winePotential: 'Пити зараз',
  sweetness: 'Сухе',
  packagingType: 'Скло',
  grapesVariety: 'Каберне Совіньйон (Cabernet Sauvignon)',
  productType: 'Вино',
  alcoholStrength: '12%',
  capacity: '0,75 л',
  wineColor: 'Червоне',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const chardonayData = {
  name: 'Kavalier (Кавальєр) Varietale Chardonnay Bianco',
  taste: 'помірно збалансований, солодкуватий, з приємною кислинкою в кінці',
  aroma: 'вишуканий, насичений, м\'який, з нотами тропічних фруктів',
  gastronomicCombinations: 'чудово підходить для супроводу рибних страв, морепродуктів, десертів та різних видів сиру',
  grapeVariety: '100% Шардоне',
  manufacturer: 'Kavalier, Італія.',
  region: 'Сицилія',
  winePotential: 'Пити зараз',
  sweetness: 'Сухе',
  coverType: 'Корок',
  packagingType: 'Скло',
  grapesVariety: 'Шардоне (Chardonnay)',
  productType: 'Вино',
  alcoholStrength: '12%',
  capacity: '0,75 л',
  wineColor: 'Біле',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const fragolinoBiancoData = {
  name: 'Kavalier (Кавальєр) Fragolino Bianco',
  desc: 'ароматизований напій на основі вина "Фраголіно Б\'янко".',
  taste: 'приємний, солодкий, фруктовий',
  aroma: 'фруктовий, свіжий та ароматний',
  gastronomicCombinations: 'вдало поєднується з фруктовим асорті або десертами на кшталт чізкейка або ягідного суфле',
  manufacturer: 'Kavalier, Італія.',
  sweetness: 'Напівсолодкий (Semi Sweet)',
  packagingType: 'Скло',
  productType: 'Винний напій',
  drinkType: 'Ігристі',
  alcoholStrength: '7%',
  capacity: '0,75 л',
  wineColor: 'Біле',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const fragolinoRossoData = {
  name: 'Kavalier (Кавальєр) Fragolino Rosso',
  desc: 'ароматизований напій на основі вина "Фраголіно Росо".',
  taste: 'приємний, солодкий, фруктовий',
  aroma: 'фруктовий, свіжий та ароматний',
  gastronomicCombinations: 'вдало поєднується з фруктовим асорті або десертами на кшталт чізкейка або ягідного суфле',
  manufacturer: 'Kavalier, Італія.',
  sweetness: 'Напівсолодкий (Semi Sweet)',
  packagingType: 'Скло',
  productType: 'Винний напій',
  drinkType: 'Ігристі',
  alcoholStrength: '7%',
  capacity: '0,75 л',
  wineColor: 'Червоне',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const lambruscoData = {
  name: 'Kavalier (Кавальєр) Lambrusco Emilia Vino Frizzante Bianco Amabile',
  taste: 'гармонійний, ніжний, фруктовий, вино з неперевершеними легкими бульбашками',
  aroma: 'розвинений, тонкий, фруктовий',
  gastronomicCombinations: 'підходить до різних холодних закусок, рибних страв, фруктів та десертів, а також чудово поєднується з різними пастами',
  grapeVariety: 'Ламбруско з Емілії',
  manufacturer: 'Kavalier, Італія.',
  region: 'Емілія-Романья',
  classification: 'IGT/IGP',
  sweetness: 'Напівсолодке',
  packagingType: 'Скло',
  grapesVariety: 'Ламбруско (Lambrusco)',
  productType: 'Ігристе вино',
  alcoholStrength: '8%',
  capacity: '0,75 л',
  wineColor: 'Біле',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const merlotRosatoData = {
  name: 'Kavalier (Кавальєр) Veneto Igt Merlot Rosato',
  taste: 'добре збалансований, має м\'який фруктовий присмак і тривалий післясмак',
  aroma: 'насичений, свіжий, з нотками фруктів',
  gastronomicCombinations: 'відмінно підходить для супроводу холодних страв, салатів, риби та різних видів сиру',
  grapeVariety: '100% Мерло',
  manufacturer: 'Kavalier, Італія.',
  region: 'Венето',
  winePotential: 'Пити зараз',
  sweetness: 'Сухе',
  coverType: 'Корок',
  packagingType: 'Скло',
  grapesVariety: 'Мерло (Merlot)',
  productType: 'Вино',
  alcoholStrength: '12%',
  capacity: '0,75 л',
  wineColor: 'Рожеве',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const merlotRossoData = {
  name: 'Kavalier (Кавальєр) Varietale Merlot Rosso',
  taste: 'легкий, збалансований, м\'який',
  aroma: 'інтенсивний, освіжаючий, м\'який, з нотками фруктів',
  gastronomicCombinations: 'відмінно підходить для супроводу м\'ясних страв, печива, пасти, піци та різних видів сиру',
  grapeVariety: '100% Мерло',
  manufacturer: 'Kavalier, Італія.',
  region: 'Сицилія',
  winePotential: 'Пити зараз',
  sweetness: 'Сухе',
  coverType: 'Корок',
  packagingType: 'Скло',
  grapesVariety: 'Мерло (Merlot)',
  productType: 'Вино',
  alcoholStrength: '12%',
  capacity: '0,75 л',
  wineColor: 'Червоне',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const neroData = {
  name: 'Kavalier (Кавальєр) Sicilia Nero Davola',
  taste: 'гармонійний, збалансований, м\'який довготривалий приємний післясмак',
  aroma: 'виноградний, наповнений нотками червоних фруктів та квітів',
  gastronomicCombinations: 'чудово підходить для супроводу барбекю, пасти, паштетів, сирів, м\'ясних страв',
  grapeVariety: '100% - Неро д\'авола',
  manufacturer: 'Kavalier, Італія.',
  region: 'Сицилія',
  classification: 'DOC',
  winePotential: 'Пити зараз',
  sweetness: 'Сухе',
  coverType: 'Корок',
  packagingType: 'Скло',
  grapesVariety: 'Неро д\'Авола (Nero d\'Avola)',
  productType: 'Вино',
  alcoholStrength: '12,5%',
  capacity: '0,75 л',
  wineColor: 'Червоне',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const pinotGrigioData = {
  name: 'Kavalier (Кавальєр) Terre Siciliane Igt Inzolia Pinot Grigio Bianco',
  taste: 'м\'який, приємний, добре збалансований',
  aroma: 'вишуканий, насичений, з нотками квітів, прянощів та фруктів',
  gastronomicCombinations: 'відмінно підходить для супроводу рибних страв, морепродуктів, макаронів та паст, може бути аперитивом',
  grapeVariety: '100% Піно Гріджіо',
  manufacturer: 'Kavalier, Італія.',
  region: 'Сицилія',
  winePotential: 'Пити зараз',
  sweetness: 'Сухе',
  coverType: 'Корок',
  packagingType: 'Скло',
  grapesVariety: 'Піно Гріджио (Pinot Grigio)',
  productType: 'Вино',
  alcoholStrength: '12%',
  capacity: '0,75 л',
  wineColor: 'Біле',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

const rossoSemiData = {
  name: 'Kavalier (Кавальєр) Vino Rosso Senza Semi Sweet',
  taste: 'насичений, добре збалансований',
  aroma: 'приємний, виноградний, з нотками фруктів',
  gastronomicCombinations: 'відмінно підходить для супроводу десертів, добре поєднується з основними та овочевими стравами',
  grapeVariety: 'купаж червоних сортів',
  manufacturer: 'Kavalier, Італія.',
  region: 'Венето',
  classification: 'IGP, DOP',
  winePotential: 'Пити зараз',
  sweetness: 'Напівсолодке',
  coverType: 'Корок',
  packagingType: 'Скло',
  grapesVariety: 'Купаж',
  productType: 'Вино',
  alcoholStrength: '12%',
  capacity: '0,75 л',
  wineColor: 'Червоне',
  brand: 'Kavalier',
  manufactureCountry: 'Iталiя'
};

export {
  biancoSemiData,
  cabernetData,
  chardonayData,
  fragolinoBiancoData,
  fragolinoRossoData,
  lambruscoData,
  merlotRosatoData,
  merlotRossoData,
  neroData,
  pinotGrigioData,
  rossoSemiData
}
