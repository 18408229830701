import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import Footer from "../components/Footer";
import { 
  biancoSemi,
  cabernet,
  chardonay,
  fragolinoBianco,
  fragolinoRosso,
  lambrusco,
  merlotRosato,
  merlotRosso,
  nero,
  pinotGrigio,
  rossoSemi 
} from '../js/images';

import { 
  biancoSemiData,
  cabernetData,
  chardonayData,
  fragolinoBiancoData,
  fragolinoRossoData,
  lambruscoData,
  merlotRosatoData,
  merlotRossoData,
  neroData,
  pinotGrigioData,
  rossoSemiData 
} from "../js/text";

const GridDetailStyle = styled.section`
  --section-bg-s1-margin: 0 auto;
  --section-bg-s1-min_height: 90vh;
  --section-bg-s1-max_height: 100%;
  --section-bg-s1-padding: 20px;
  --section-bg-s1-bg_color: rgba(0,0,0, 0.6);
  --section-bg-s1-bg_image: url(${p => p.image});
  --section-bg-s1-bg_position: center;
  --section-bg-s1-bg_repeat: no-repeat;
  --section-bg-s1-bg_size: cover;
  --section-bg-s1-box_shadow: none;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 50px 1fr;
  overflow: hidden;
  min-height: 90vh;
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
  background-color: #fff;

  .back {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: #fff;
    padding: 10px;
  }

  .detail-image {
    width: 100%;
    height: 100%;
    background: url(${p => p.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #fff;
  }

  .detail-text {
    width: 100%;
    height: 100%;
    background-color: #fff;
    font-family: Nunito,Roboto,sans-serif;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  .previous-button {
    position: relative;
    background-color: #ccc;
    border: none;
    color: #fff;
    padding: 15px 15px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .previous-button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translateY(-50%);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #fff;
  }

  @media screen and (max-width:640px) {
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr 1fr;
    .detail-text {
        padding: 50px;
    }
    .back {
        grid-column-end: 2;
    }
  }

  @media screen and (min-width: 1281px) {
    padding-top: 110px;
  }
`;

const images = {
  biancoSemi: biancoSemi,
  cabernet: cabernet,
  chardonay: chardonay,
  fragolinoBianco: fragolinoBianco,
  fragolinoRosso: fragolinoRosso,
  lambrusco: lambrusco,
  merlotRosato: merlotRosato,
  merlotRosso: merlotRosso,
  nero: nero,
  pinotGrigio: pinotGrigio,
  rossoSemi: rossoSemi
};

const data = {
  biancoSemi: biancoSemiData,
  cabernet: cabernetData,
  chardonay: chardonayData,
  fragolinoBianco: fragolinoBiancoData,
  fragolinoRosso: fragolinoRossoData,
  lambrusco: lambruscoData,
  merlotRosato: merlotRosatoData,
  merlotRosso: merlotRossoData,
  nero: neroData,
  pinotGrigio: pinotGrigioData,
  rossoSemi: rossoSemiData
};

const ProductDetails = () => {
  const navigate = useNavigate();
  const product = useParams();

  const back = () => {
    navigate('/products');
  }

  return (
    <div className="product-details-container">
      <GridDetailStyle id="#" image={images[product.name]}>
        <div className="back"><button onClick={back} class="previous-button"></button></div>
        <div className="detail-image"></div>
        <div className="detail-text">
          <h2><b>Про товар</b></h2>
          {data[product.name].productType === 'Вино' && <div>Вино <b>{data[product.name].name}</b> має чарівний і багатий смак.</div>}
          {data[product.name].productType === 'Винний напій' && <div>Винний напій <b>{data[product.name].name}</b> {data[product.name].desc}</div>}
          {data[product.name].productType === 'Ігристе вино' && <div>Вино <b>{data[product.name].name}</b> відноситься до ігристих вин слабкого тиску, які в Італії називаються "Фрізант".</div>}
  
          <div><b>Смак:</b> {data[product.name].taste}</div>
  
          <div><b>Аромат:</b> {data[product.name].aroma}</div>
  
          <div><b>Гастрономічні поєднання:</b> {data[product.name].gastronomicCombinations}</div>
  
          {data[product.name].grapeVariety && <div><b>Сорт винограду:</b> {data[product.name].grapeVariety}</div>}
  
          <div><b>Виробник:</b> {data[product.name].manufacturer}</div>

          <br />
          <h2><b>Характеристики</b></h2>
          {data[product.name].region && <div><b>Регіон:</b> {data[product.name].region}</div>}
          {data[product.name].classification && <div><b>Класифікація:</b> {data[product.name].classification}</div>}
          {data[product.name].winePotential && <div><b>Потенціал вина:</b> {data[product.name].winePotential}</div>}
          <div><b>Солодкість:</b> {data[product.name].sweetness}</div>
          {data[product.name].coverType && <div><b>Тип кришки:</b> {data[product.name].coverType}</div>}
          <div><b>Тип упаковки</b> {data[product.name].packagingType}</div>
          {data[product.name].grapesVariety && <div><b>Сорт винограду</b> {data[product.name].grapesVariety}</div>}
          <div><b>Тип товару</b> {data[product.name].productType}</div>
          {data[product.name].drinkType && <div><b>Тип напою</b> {data[product.name].drinkType}</div>}
          <div><b>Міцність алкоголю</b> {data[product.name].alcoholStrength}</div>
          <div><b>Ємність</b> {data[product.name].capacity}</div>
          <div><b>Колір вина</b> {data[product.name].wineColor}</div>
          <div><b>Бренд</b> {data[product.name].brand}</div>
          <div><b>Країна виробник</b> {data[product.name].manufactureCountry}</div>
          <br />
          <div>Інформація про технічні характеристики, комплекту поставки, країні виготовлення і
            зовнішньому вигляді товару носить довідковий характер і грунтується на останніх доступних відомостях від виробника.
          </div>
        </div>
      </GridDetailStyle>
      <Footer />
    </div>
  )
}

export default ProductDetails;