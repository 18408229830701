import styled from "styled-components";

import Grid from "../components/Grid";
import GridItem from "../components/GridItem";
import Footer from "../components/Footer";

const GridSectionStyle = styled.section`
  --section-bg-s1-margin: 0 auto;
  --section-bg-s1-min_height: 100vh;
  --section-bg-s1-max_height: 100%;
  --section-bg-s1-padding: 20px;
  --section-bg-s1-bg_color: rgba(0,0,0, 0.83);
  --section-bg-s1-bg_repeat: no-repeat;
  --section-bg-s1-bg_size: cover;
  --section-bg-s1-box_shadow: none;
  min-height: 100vh !important;
  height: 100%;
  scroll-margin-top: 90px;

  color: white;

  .product-tags {
    font-family: Nunito,Roboto,sans-serif;
  }

  .product-tags.last {
    border-top: 1px solid lightgray;
  }

  hr {
    margin: 0;
  }

  @media screen and (max-width: 640px) {
    .shampain-area {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }

  @media screen and (min-width: 1281px) {
    padding-top: 110px;
  }
`;

const ProductsPage = () => {
  return (
    <div className="products-body-container">
      <GridSectionStyle>
        <h1 className="product-tags">Вина</h1>
        <Grid>
          <GridItem bgImage='biancoSemi'><div className="productImage"></div><div className="productText">Bianco Semi</div></GridItem>
          <GridItem bgImage='cabernet'><div className="productImage"></div><div className="productText">Cabernet</div></GridItem>
          <GridItem bgImage='chardonay'><div className="productImage"></div><div className="productText">Chardonay</div></GridItem>
          <GridItem bgImage='merlotRosso'><div className="productImage"></div><div className="productText">Merlot Rosso</div></GridItem>
          <GridItem bgImage='merlotRosato'><div className="productImage"></div><div className="productText">Merlot Rosato</div></GridItem>
          <GridItem bgImage='nero'><div className="productImage"></div><div className="productText">Nero</div></GridItem>
          <GridItem bgImage='pinotGrigio'><div className="productImage"></div><div className="productText">Pinot Grigio</div></GridItem>
          <GridItem bgImage='rossoSemi'><div className="productImage"></div><div className="productText">Rosso Semi</div></GridItem>
        </Grid>
        <h1 className="product-tags last">Ігристі вина</h1>
        <div className="shampain-area">
          <GridItem bgImage='fragolinoBianco'><div className="productImage"></div><div className="productText">Fragolino Bianco</div></GridItem>
          <GridItem bgImage='fragolinoRosso'><div className="productImage"></div><div className="productText">Fragolino Rosso</div></GridItem>
          <GridItem bgImage='lambrusco'><div className="productImage"></div><div className="productText">Lambrusco</div></GridItem>
        </div>
      </GridSectionStyle>
      <Footer />
    </div>
  )
}

export default ProductsPage;