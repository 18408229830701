import { useNavigate } from "react-router-dom";

import '../styles/grid.scss';

const GridItem = props => {
  const navigate = useNavigate();
  const {children, bgImage} = props;

  const routeChange = () => {
    navigate(bgImage);
  }

  return <div onClick={routeChange} className={'products-item ' + bgImage}>{children}</div>;
};
  
export default GridItem;