/** Global colors */
export const whiteRegular = 'rgba(255, 255, 255, 1)';
export const blackRegular = 'rgba(0, 0, 0, 1)';
/**  ----------------------------------------------  */

/** Buttons styles */
export const buttonColor = '#2a9d90';
export const buttonHoverColor = '#fff';
export const buttonTextColor = '#000';
export const buttonTextHoverColor = '#fff';
/** ----------------------------------------------- */