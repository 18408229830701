import { Route, Routes } from 'react-router-dom';

import IndexPage from '../pages/index';
import ProductsPage from '../pages/products';
import ProductDetails from '../pages/productDetails';

const Routing = () => {
  return (
    <Routes>
      <Route exact path="/" element={<IndexPage/>} />
      <Route exact path="/products" element={<ProductsPage/>} />
      <Route path="/products/:name" element={<ProductDetails />} />
      <Route path="" element={<IndexPage/>} />
      <Route path="*" element={<IndexPage/>} />
      <Route element={<IndexPage/>} />
    </Routes>
  );
}

export default Routing;
